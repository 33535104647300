import React, { useEffect } from "react"
import { Appear, Button, LogoTransitionType, SEO } from "../components"
import { useLayout } from "../context"
import { Page } from "../models/Page"

const NotFoundPage = (props: Page) => {
  const { transitionStatus } = props
  const { startLogoTransition } = useLayout()

  useEffect(() => {
    if (transitionStatus === "entered") {
      setTimeout(() => startLogoTransition(LogoTransitionType.Sad), 1000)
    }
  }, [transitionStatus])

  return (
    <>
      <SEO title="404: Not found" />
      <div className="container">
        <Appear order={1}>
          <h1 className="w-5/6 lg:w-7/12 xl:w-1/2 heading-l lg:heading-xl mb-12 lg:mb-16">
            We can't find the page you're looking&nbsp;for
          </h1>
        </Appear>
        <div className="w-5/6 lg:w-7/12 xl:w-5/12 lg:mx-auto pb-4">
          <Appear order={2}>
            <p className="paragraph-s lg:paragraph-m mb-8">
              This page might have been moved. Try going back to our homepage.
            </p>
          </Appear>
          <Appear order={3}>
            <Button to="/">Back to design.studio</Button>
          </Appear>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
